// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { db } from "../configs/firebase"
import { store } from "./store"

// ** Axios Imports

const invoiceData = {
  sub: null,
  userId: null
}

const invoiceDataSaved = {
  sub: null,
  userId: null
}

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    updatedAt: Date.now().toString(),
    invoiceData: [],
    invoiceDataSaved: []
  },
  reducers: {
    setMonthInvoiceForUser(state, action) {
      state.invoiceData = action.payload
      store.updatedAt = Date.now().toString()
    },
    setSavedInvoice(state, action) {
      state.invoiceDataSaved = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToSubmittedInvoice = (userId, month) => {
  if (invoiceDataSaved.sub) {
    invoiceDataSaved.sub()
  }

  invoiceDataSaved.userId = userId
  store.dispatch(invoicesSlice.actions.setSavedInvoice([]))
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (userId) constrainsAppointments.push(where("userId", "==", userId))
  // if (month) constrainsAppointments.push(where("invoiceMonth", "==", month))

  try {
    invoiceDataSaved.sub = onSnapshot(
      query(collection(db, "employeeInvoices"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          appointments.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(invoicesSlice.actions.setSavedInvoice(appointments))
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToMonthInvoiceForUser = (userId, month) => {
  if (invoiceData.sub) {
    if (userId && invoiceData.userId && invoiceData.userId !== userId) {
      invoiceData.sub()
    } else if (month && invoiceData.month && invoiceData.month !== month) {
      invoiceData.sub()
    } else {
      return
    }
  }
  if (!userId) {
    return store.dispatch(invoicesSlice.actions.setMonthInvoiceForUser([]))
  }

  invoiceData.userId = userId
  invoiceData.month = month
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (userId) constrainsAppointments.push(where("theraphist.id", "==", userId))
  if (month) constrainsAppointments.push(where("invoiceMonth", "==", month))

  try {
    invoiceData.sub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          if (doc.data().paymentStatus !== "PENDING") {
            appointments.push({
              id: doc.id,
              ...doc.data(),
              dateTime: doc.data().dateTime.toDate()
            })
          }
        })
        store.dispatch(
          invoicesSlice.actions.setMonthInvoiceForUser(
            orderBy(appointments, "dateTime")
          )
        )
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default invoicesSlice.reducer
